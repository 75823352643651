.incentive-table-cell {
    height: 80px;
    border-radius: 20px;
    /* border-bottom-left-radius: 15px; */
    /* border-bottom-left-radius: 15px; */
}

.incentive-inputs {
    margin: 0;
    height: 40px;
    width: 100px;
    text-align: start;
    padding-left: 5px;
    padding-right: 5px;
}

.incentive-btn {
    margin: 0;
}

.incentive-table-cell-btn {
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 15px;
    border-radius: 20px;
    margin-right: 30px;
}

.incentive-cancel-btn {
    height: 2.5rem;
    width: 5rem;
    background-color: darkred;
    color: white;
    border-radius: 5px;
    margin: 0;
}

.incentive-select {
    margin-left: 20px;
    margin-top: 20px;
}

.table-container {
    width: 43.75rem;
}

.first-cell-incentive {
    padding-left: 20px ;
    font-weight: 600 !important;
}

.center-align {
    display: flex;
    justify-content: center;
}