@font-face {
  font-family: 'Roboto';
  src: local('Roboto'), url(./font/Roboto/Roboto-Regular.woff) format('woff');
  /* other formats include: 'woff2', 'truetype, 'opentype',
                            'embedded-opentype', and 'svg' */
}

body {
  background-color: #ffffff !important;
}

.qcpanelclass {
  width: 99%;
  height: 70vh;
  overflow-y: auto;
  margin: 0px auto;
}

.table-striped tbody tr:nth-of-type(odd) {
  background-color: #ffffff;
}

.table-striped tbody tr:nth-of-type(even) {
  background-color: #ffffff;
}

.qcpanelclass>table {
  width: 200%;
  height: 100%;

}

.container,
.container-fluid,
.container-lg,
.container-md,
.container-sm,
.container-xl {
  padding: 0px !important;
}

#sidebar-wrapper {
  padding: 0px !important;
}

.row {
  margin: 0px !important;
}

.col-10 {
  max-width: 85% !important;
  padding: 0px;
  flex: 0px;
}

.col-2 {
  flex: 0px !important;
}

.MuiAppBar-colorPrimary {
  background-color: #CDECF4 !important;
  border-top: 0px !important;
  border: 2px solid #0375B0;
  box-sizing: border-box;
}

.makeStyles-drawerPaper-8 {
  width: 15% !important;
  background-color: #CDECF4 !important;
  border-bottom: 2px solid #0375B0;
  border-left: 2px solid #0375B0;

  box-sizing: border-box;
}

.MuiDrawer-paperAnchorDockedLeft {
  border-right: 2px solid #0375B0;
}

#sidebar-wrapper {
  max-width: 15% !important;
  padding: 0px !important;
}

.makeStyles-logo-10,
.jss10 {
  width: 126px;
  height: 124px !important;
  margin-top: 11px !important;

}

.makeStyles-logoDiv-22,
.jss22 {
  margin-bottom: 60px;
  background-color: #ffffff !important;
  border-bottom: 2px solid #0375B0;
  border-right: 0px !important;
  box-sizing: border-box;
}

.makeStyles-appBarGrid-9,
.jss9,
.jss8 {
  background-color: #CDECF4 !important;
}

.qc-merchentid {
  overflow: hidden;
  text-overflow: ellipsis;
}

.table-hover tbody tr:hover td,
.table-hover tbody tr:hover th {
  background-color: #CDECF4;
}

.h5-small {
  text-align: center;
  color: #000000;
  padding-top: 3px;
  padding-bottom: 3px;
  margin: 0px;
  font-size: 1rem;
}

.nav-link.unselected {
  padding: 1rem 1.5rem;
}

.nav-link.active>.h5-small {
  color: #ffffff;
}

.nav-link.active {
  display: block;
  padding: 0.5rem 0.5rem;
  background: #0375B0;
  border-radius: 5px;
}

.MuiToolbar-regular {
  height: 5px;
}

.admin_deatails {
  font-size: 20px;
  color: #000000;
  float: right;
  font-family: 'Roboto';
  margin-top: 0px;
  text-align: initial;
  padding-left: 5px;
}

table {
  /* border: 2px solid #000000; */
}

th {
  border: 1px solid #000000;
  border-width: 0.5px;
}

.merchentList_td {
  border: 1px solid #000000;
  padding-top: 9px;
  padding-bottom: 9px;
  border-width: 0.5px;
  word-break: break-all;
  padding: 9px;
  /* white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; */
}

.merchentList_table .merchentList_tr .merchentList_th {

  /* text-align: center; */
  padding: 9px;
}

.merchantdetails {
  border: none;
}

.merchantdetails_tr {
  width: 100%;
  line-height: 50px;
}

.merchantdetails_td {
  width: 25%;
  font-weight: 600;
  font-size: 14px;
  float: left;

}

.merchantdetails_td1 {
  width: 40%;
  font-size: 14px;
  float: left;
}

.search {
  width: 40%;
  float: right;
  display: block;
  /* padding-bottom: 2%;
  padding-top:2%; */
}


.editMerchent {
  margin-top: 30px !important;
  width: 98%;
  margin: 0px auto;
}

.editLabel {
  text-align: initial;
  margin-bottom: 3px;
  font-weight: 600;
}

.editInput {
  text-align: initial;
  margin: initial;
  width: 50%;
  margin-bottom: 10px;
  overflow: auto;
}

.time-picker {
  height: 25px;
  text-align: initial;
  margin: 0;
  padding: 0;
  position: fixed;
}

.search-input-container {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 20px;
  margin-right: 5%;
  padding: 20px 0;
}

.merchant-list-search-input {
  width: 500px !important;
}

@media (min-width: 600px) {

  .makeStyles-drawer-4 {
    width: 15% !important;
  }

  .makeStyles-appBar-5,
  .jss5 {
    margin-left: 15% !important;
    width: 85% !important;
  }

  .MuiToolbar-regular {
    min-height: 124px !important;
    margin-top: 10px;
  }
}

.editButton {
  display: block;
  margin-bottom: 20px;
  width: 50%
}

.cancel_button {
  display: inline-block;
  width: 14%;
}

.update_button {
  margin-left: 54px;
  display: inline-block;
}

.modal.show .modal-dialog {
  margin-top: 160px;
}

.tittle {
  position: relative;
  width: 100%;
  border: 2px solid #0375B0;
}

.tittle>h1 {
  margin-top: 165px;
  text-align: left;
  padding-left: 10px;
}

/* .css-1d3z3hw-MuiOutlinedInput-notchedOutline {
  border-width: 0px !important;
}

.css-1u3bzj6-MuiFormControl-root-MuiTextField-root {
  border: 1px solid #000000 !important;
} */

.input_text_fields {
  width: 100% !important;  
  max-width: 100% !important;  
}
.input_text_fields_mobile {
  width: 80% !important;  
  max-width: 80% !important;  
}
.input_icon_mobile {
  width: 20% !important;
  max-width: 20% !important;
  font-size: x-large;
  margin: 0px 0px 0px 16px;
}
.input_icon_mobile svg:hover {
  color: rgb(90, 234, 90) !important;
}

.jss8 {
  width: 15% !important;
}

.color_border_validation {
  border: 1px solid#C4C4C4;
}
.background_validation{
border-bottom: 1px solid #C4C4C4;
}

img.image_right {
  width: 35px;
}

.image_wrong_div {
  width: 35px;
  height: 35px;
  display: flex;
  justify-content: center;
  background-color: #C4C4C4;
  align-items: center;

}

.image_wrong {
  width: 18px;
  height: 18px;
}

.image_validation {
  display: flex;
  justify-content: center;
  margin: 0px auto;
}

.button_submit {
  background: #E98424;
  width: 50%;
  color: #ffffff;
}

.cheque_submit {
  background: #E98424;
  width: 50%;
  color: #ffffff;
  margin-top: 6rem;
}

.height_cheque {
  display: inline-block;
  height: 167px;
  object-fit: cover;
  width: 215px
}

.cheque_button {
  width: 0px;
  background: none;
}

.label_cheque {
  display: inline-block;
  width: 16rem;
}

.input_cheque {
  display: inline-block;
  float: right;
  width: 50%;
  border: 1px solid #0375B0;
  line-height: 1.5rem;

}

.input_cheque:focus {
  outline: none;
  border: 1px solid #0375B0;

}

.button_submit_check {
  /* margin-top: 9rem; */
  /* width: 33%; */
  background: #E98424;
  color: #ffffff;
  margin-left: 16rem;
}

.text_instructions {
  /* position: absolute;
  bottom: 0;
  left:/ 0; */
  /* padding-top: 16%; */
  background-color: #CDECF4;
}

.form-control {
  border: 1px solid #0375B0;

}

.react-datepicker-wrapper {
  display: inline-block;
  float: right;
  width: 50% !important;
  border: 1px solid #0375B0;
  line-height: 1.5rem;
}

.datepicker_border {
  border: 1px solid #0375B0;
  line-height: 1.5rem;
}

.my-modal {
  width: 600px;
  /* Occupy the 90% of the screen width */
  max-width: 600px;
}

.reason_shopimage {
  width: 44%;
}
.reason_shopboard{

  width:30.5%;

}

.section_instruction{
  top:90px;
  left:15px;
  width:53% !important;
  max-width: 53% !important;
}

.category_header {
  font-size: 22px;
  margin: 6px;
  padding: 0;
  display: flex;
  justify-content: flex-end;
  /* grid-template-columns: 1fr 1fr 1fr; */
  gap: 10%;
}
.section_instruction1{
  top:60px;
  left:15px;
  width:60% !important;
  max-width: 60% !important;
}

.freeDeliveries{
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
}

.deliveryFlex{
  display: flex;
  flex-direction: row;
}

.addFreeDeliveries{
  text-align: initial;
  margin: initial;
  width: 15%;
  margin-bottom: 10px;
  margin-top: 5px;
}

.addFreeDeliveryButton{
  margin: initial;
  width: 10% !important;
  background-color: #0375B0;
  color: #ffffff;
  margin-left: 10px;
}