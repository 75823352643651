.delivery-details-header {
    text-align: center;
    margin-bottom: 0;
    margin-right: 30px;
}

.table-header-cell {
    padding: 0 0.625rem;
    text-align: center;
    /* width: 12.5rem; */
    border: none;
    background-color: white;
    color: black;
}

.table-data-cell {
    text-align: center;
    padding-top: 0.9375rem;
    padding-bottom: 0.9375rem;
    font-size: 15px;
}

.table-data-cell-for-block {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 0.9375rem;
    padding-bottom: 0.9375rem;
    text-align: center;
}

.order-summ-table-data-cell {
    text-align: center;
    padding-top: 0.9375rem;
    padding-bottom: 0.9375rem;
}

.first-cell {
    border-top-left-radius: 0.9375rem;
    border-bottom-left-radius: 0.9375rem;
}

.first-cell-data {
    display: inline-block;
    cursor: pointer;
    color: #1e9dff;
    font-weight: 600;
}

.last-cell {
    border-bottom-right-radius: 0.9375rem;
    border-top-right-radius: 0.9375rem;
}

.table-data-row {
    box-shadow: 0 0.125rem 0.3125rem rgba(0, 0, 0, 0.3);
    border-radius: 1.25rem;
}

.delivery-table {
    margin-top: 1.25rem;
    margin-left: 0;
    border-collapse: separate;
    border-spacing: 0 1.25rem;
    width: 100% !important;
    padding: 0 1.25rem;
    margin-bottom: 60px;
}

.amber-text {
    color: #ff8d28;
}

.today-sumary-container {
    border: 0.0625rem solid black;
    border-radius: 0.9375rem;
    margin: 0 3.75rem;
    padding: 0.625rem 1.25rem;
}

.summary-city-container {
    display: flex;
    align-items: center;
    gap: 3.75rem;
}

.delivery-details-select {
    width: 12.5rem;
    height: 1.875rem;
    border-radius: 0.3125rem;
    background-color: white;
}

.status-count-container {
    margin-top: 1.25rem;
    display: flex;
    justify-content: space-around;
}

.summary-count {
    text-align: center;
    font-size: 1.5625rem;
    font-weight: 600;
}

.table-header-row {
    position: sticky;
    top: 4.375rem;
}

.radio-container {
    display: flex;
    margin-top: 0.625rem;
    gap: 0.3125rem;
    align-items: center;
    cursor: pointer;
}

.cross-icon {
    width: 0.9375rem;
    height: 0.9375rem;
    position: absolute;
    right: 1%;
    top: 1%;
}

.radio-input {
    margin: 0;
    width: 1.25rem;
    height: 1.25rem;
}

.checkbox-input {
    margin: 0;
    width: 1.25rem;
    height: 1.25rem;
}

.rider-select {
    width: 12.5rem;
    margin-top: 0.625rem;
    margin-left: 0.3125rem;
    margin-bottom: 0.625rem;
    height: 1.875rem;
    border-radius: 0.3125rem;
    background-color: white;
}

.today-summary-header {
    margin-bottom: 0;
}

.delivered-count {
    color: #5ca117;
}

.action-pending-count {
    color: #ff0000;
}

.cancelled-count {
    color: #ff6bb4;
}

.stage-info-container {
    display: flex;
    margin-left: 5rem;
    align-items: center;
    gap: 1.25rem;
    margin-top: 0.625rem;
}

.stage-box-text-container {
    display: flex;
    align-items: center;
    gap: 0.3125rem;
}

.amber-box {
    height: 1.875rem;
    width: 1.875rem;
    background-color: #ff8d28;
}

.red-box {
    height: 1.875rem;
    width: 1.875rem;
    background-color: #ff0000;
    display: flex;
    justify-content: center;
    align-items: center;
}

.intented-data {
    margin-left: 1.25rem;
    margin-bottom: 1.25rem;
}

.delivery-option-submit-btn {
    height: 2.5rem;
    width: 100%;
    background-color: #0d669e;
    color: white;
    border-radius: 5px;
    margin-top: 20px;
}

.delivery-option-submit-btn-disabled {
    height: 2.5rem;
    width: 100%;
    background-color: grey;
    color: white;
    border-radius: 0.313rem;
    margin-top: 1.25rem;
}

.delivery-option-dialog {
    padding: 2rem 2rem;
}

.order-mod-dialog {
    padding: 2rem 1rem;
}

.select-delivery-option-header {
    margin-bottom: 1.25rem;
}

.order-mod-img {
    width: 2.5rem;
    height: 2.5rem;
}

.price-container-mod {
    display: flex;
    align-items: center;
    width: 5rem;
    justify-content: center;
}

.sticky-btn {
    position: sticky;
    bottom: 2.188rem;
}

.mod-btn-container {
    display: flex;
    justify-content: center;
    align-items: center;
}

.two-btn-container {
    display: flex;
    gap: 1.25rem;
    align-items: center;
}

.arrange-from-header {
    margin-bottom: 2.5rem;
}

.two-btn-text {
    color: #0d669e;
    cursor: pointer;
}

.pdname-image-container {
    display: flex;
    gap: 10px;
}

.order-item-name {
    font-weight: 600;
    text-align: left;
    line-height: normal;
}

.req-qty-text {
    color: #bebebe;
    text-align: left;
}

.order-summ-count {
    background-color: #f2f2f2;
    display: inline-block;
    padding: 0.625rem 0.938rem;
    border-radius: 0.313rem;
    color: #0d669e;
    font-weight: 600;
}

.price-container-view {
    display: flex;
    justify-content: center;
    align-items: center;
}

.sticky-header {
    position: sticky;
    top: 0;
    background-color: white;
    padding-bottom: 0.625rem;
}

.btn-disabled {
    background-color: grey;
}

.border-top-border {
    border-top:1px solid #ececec;
}

.action-btn-disabled {
    height: 2.5rem;
    width: 5rem;
    background-color: grey;
    color: white;
    border-radius: 5px;
}

.arrange-view-header {
    background-color: #ffe599;
    padding: 0.625rem 0;
}

.pagination-stack {
    display: block !important;
    width: 100% ;
    margin-bottom: 0.313rem;
    left: 0;
    right: 0;
    position: fixed;
    bottom: 0;
    background-color: #cdecf4;
    padding: 0.313rem 0.188rem;
}

.pagination-text {
    display: inline-block;
    width: 30%;
}

.refresh-icon {
    height: 1.25rem;
    color: #0d669e;
    cursor: pointer;
}

.header-refresh-container {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 0.625rem;
}

.refund-buttons-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 3.125rem;
}

.custom-refund-text {
    margin-top: 1.25rem;
    width: 12.5rem;
    margin-bottom: 0;
    cursor: pointer;
    color: #0d669e;
}

.custom-refund-text-disabled {
    margin-top: 1.25rem;
    width: 12.5rem;
    margin-bottom: 0;
    color: grey;
}

.delivery-details-amount-input {
    width: 40%;
}

.delivery-details-comment-input {
    width: 60%;
}

.custom-inputs-container {
    display: flex;
    gap: 1.25rem;
    align-items: center;
}

.custom-refund-header {
    margin-left: 0.313rem;
    margin-bottom: 1.25rem;
}

.no-action-text-box {
    width: 25rem;
}

.no-action-header {
    margin-left: 0.313rem;
    margin-bottom: 1.25rem;
}

.action-header {
    text-align: center;
    margin-top: 0.5rem;
    margin-bottom: 0;
}

.order-det-header {
    width: 300px;
}

.pagination-div {
    position: fixed;
    bottom: 0;
    width: 100%;
    background-color: #CDECF4;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 40px;
}

.next-prev-container {
    display: flex;
    align-items: center;
    gap: 5px;
    cursor: pointer;
}

.next-prev-icon {
    color: black;
    height: 20px;
}

.next-prev-icon-disabled {
    color: grey;
    height: 20px;
}

.next-prev-text-disabled {
    color: grey;
}

.order-id-cell {
    width: 7.5rem;
}

.stage-info-search-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.search-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 5rem;
    margin-top: 0.625rem;
    gap: 0.625rem;
}

.no-data-text {
    text-align: center;
    margin-top: 3.75rem;
}

.status-cell {
    word-break: normal;
}

.city-select-container {
    margin-left: 5rem;
}

.select-city-label {
    margin-bottom: 5px;
}
