.price-edit-cell {
    display: flex;
    align-items: center;
    justify-content: flex-start !important;
    width: 100%;
}

.add-input-price {
    width: 70%;
    text-align: left;
    margin-top: 0;
    margin-left: 5px;
    margin-right: 0;
}

.nodata-text-merchant {
    margin-top: 1.5rem;
    font-size: 1.125rem;
    font-weight: 600;
}

.fullscreen-image {
    height: auto;
    width: 31.25rem;
    max-height: 50rem;
}
