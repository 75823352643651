.edit-img-file {
    position: absolute;
    right: $fs-5px;
    border-radius: 50%;
    width: $fs-24px;
    height: $fs-24px;
    background: $danger;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 0.312rem;
    cursor: pointer;
  }

  .upload-icon {
    width: 1.25rem;
    margin-right: 0.625rem;
  }

  .close-img {
    position: absolute;
    right: 0;
    top: 0;
    width: 1.875rem;
    margin-top: -5px;
    margin-right: 2px;
  }

  .images-dialog-container {
    padding: 2.125rem;
    margin-top: 1.875rem;
  }

  .images-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
  }

  .each-image-div {
    width: fit-content;
    display: flex;
    align-items: center;
    height: fit-content;
    margin-left: 0.315rem;
    margin-right: 0.315rem;
    margin-bottom: 1.875rem;
    border: 1px solid black;
    border-radius: 0.313rem;
    overflow: hidden;
    position: relative;
  }

  .each-image {
    width: 6.25rem;
  }

  .add-more-btn-container {
    text-align: center;
    margin-top: 0.625rem;
  }

  .delete-img {
    position: absolute;
    width: 1.25rem;
    top: 3%;
    right: 0;
  }

  .image-count-text {
    color: #0d669e;
    cursor: pointer;
    font-weight: 600;
  }

  .image-count-text:hover {
    text-decoration: underline;
  }

  .edit-images-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
  }

  .edit-img-container {
    position: absolute;
    top: 0;
    left: 0;
    background-color: white;
    padding-bottom: 4%;
    padding-left: 2%;
    border-radius: 5px;
  }

  .edit-img {
    width: 1.25rem;
  }
