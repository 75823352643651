input{
    display: block;
    width: 25%;
    margin: 0px auto;
    text-align: center;
    margin-top: 10px;
    -moz-appearance: textfield;
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
label{
    text-align: center;
    display: block;
    /* margin-top: 50px; */
    margin-bottom: 0.5rem;
}
.mobile_number{
    text-align: initial;
    display: block;
    margin-top: 4px;
    margin-bottom: 0.5rem;
    font-weight: 600;
}
.mobile_num_input{
    width:100%;
    text-align: initial;
    border: 1px solid #0375B0;
}
.otp{
    margin-top:15px;
    text-align: initial;
    font-weight: 600;
}
#otp{
    width:100%;
    border: 1px solid #0375B0;
    text-align: initial;
}
button{
    text-align: center;
    margin: 0px auto;
    width: auto;
    display: block;
    border: 1px solid;
    background-color: #ffff;
    border-radius: 9px;
}
h1{
    text-align: center;
    font-size: 25px;
    margin-top: 100px;
}
.Login,.Authentication{
    margin: auto auto;
    display: table;
    justify-content: center;
    align-items: center;
    margin-top: 200px;
}
.Proceed{
    display: block;
    margin-top: 20px;
    text-align: center;
}
.chatty_bao{
    margin: 0px auto;
}
.chatty_bao_logo{
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 25%;
    height:25%;
}
form.login_form {
    width: 40%;
    margin: 0px auto;
}
.login_div{
    width: 60%;
    margin: 0px auto;
}
.procced{
    margin-top: 20px;
}
.procced>button{
    width:100%;
    background-color: #E98424;
    color:#ffffff;
}
.resend_otp{
    border:none;
    text-align: right;
    text-decoration: underline;
    color:#0E86D4;
    font-size: 14px;
}