// body {
//     margin:0;
//     padding:0;
//     box-sizing: border-box;
//     background-color: #f3f7f8;
//   }
  .main {
    max-width:100%;
    height:auto;
    position: relative;
  }
  .main > .image {
      background-image: url(../../images/cashback_image.jpg);
      background-color: #cccccc;
      max-width: 95%;
      height: 451px;
      margin: 20px auto;
      background-position: center;
      background-repeat: no-repeat;
      background-size: 100% 100%;
  }
  
  .main > .content {
      max-width: 95%;
      height: 400px;
      margin:20px auto;
      background-color: #ffffff;
  }
  .content > h3 {
      font-weight: 600;
      font-size: 23px;
      position: relative;
      top:20px;
      left:15px;
  }
  ul {
      padding: 20px;
  }
  li.offer {
      margin:20px;
      font-weight: 500;
  }