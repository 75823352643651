@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;500&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  /* width: 425px; */
  margin: auto;
}

#FullName::placeholder,
#AddressLine1::placeholder {
  color: #000000 !important;
  opacity: 1;
}


.cpanel_container {
  font-size: small;
  display: flex;
  margin: auto;
  margin-top: 2rem;
  flex-direction: column;
  font-family: 'Poppins', sans-serif;
  height: 100vh;
  padding-inline: 1.2rem;
}

@media only screen and (min-width: 450px) {
  .cpanel_container {
    /* color: yellow;  */
    width: 450px;
  }
}

@media only screen and (max-width: 450px) {
  .cpanel_container {
    /* color: blue; */
    width: 100vw;
  }
}

/* img {
  width: 5rem;
  height: 5rem;
  scale: 1;
} */
.user-content {
  display: flex;
  flex-direction: column;
  gap: 6px;
}

#iagree {
  margin-left: 5%;
  word-spacing: 0.5rem;
  margin-top: 0px;
  color: #675858;
  font-size: 10px;
}

.text_input {
  font-size: 14px;
  padding: 0.4rem;
  font-family: 'Poppins', sans-serif;
  border: none;
  border-bottom: 1px solid rgba(229, 229, 229, 1);
  outline: none;
  width: 65%;
  margin-top: 0px;
  text-align: initial;
  margin: inherit;
  padding-bottom: 0px;
}

.category {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.25rem;
}

#heading {
  font-size: 18px;
  margin-top: 0px;
  text-align: left;
  /* font-weight: bold; */
}

#Iagree {
  width: 20px;
  /*Desired width*/
  height: 20px;
  /*Desired height*/
  cursor: pointer;
  margin-block: 1rem;
  color: #675858;
  font-size: 10px;
}

.redtext {
  color: red;
}

.bluetext {
  color: #156CEF !important;

}

.bluetext>a {
  color: #156CEF !important;

}

.bluetext>a:hover {
  color: #156CEF !important;
  text-decoration: none;

}

.category-button {
  margin: auto;
}

.submitbutton {
  padding: 0.6rem 2rem;
  font-size: 15px;
  background-color: #0375B0;
  color: #ffffff;
  border-radius: 9px;
  min-width: 120px;
  border: none;
}

.submitbutton_disable {
  padding: 0.6rem 2rem;
  font-size: 15px;
  background-color: #e6ebee;
  color: #ffffff;
  border-radius: 9px;
  min-width: 120px;
  border: none;
}

#line {
  margin-block: 1rem;
}

#hide {
  visibility: hidden;
}

#img {
  width: 35%;
  float: right;
}

.label_style {
  color: rgba(0, 0, 0, 0.35);
  font-size: 14px;
  margin-bottom: 0px;
}

a {
  text-decoration: none;
}

.powered {
  height: 11px;
  margin: 5px 0px 0px 0px;
  float: right !important;
}

.textplace::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: #212529;
}

.textplace::-moz-placeholder {
  /* Firefox 19+ */
  color: #212529;
}

.textplace:-ms-input-placeholder {
  /* IE 10+ */
  color: #212529;
}

.textplace:-moz-placeholder {
  /* Firefox 18- */
  color: #212529;
}

