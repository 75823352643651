.curved-cell {
    height: 3.75rem;
    border: 1px solid lightgray;
    border-radius: 0.625rem;
    display: flex;
    align-items: center;
    padding-left: 0.625rem;
    color: grey;
    word-break: normal !important;
}

.add-cell {
    background-color: #f8f8f8;
}

.input-div {
    position: relative;
}

.remaining-text {
    position: absolute;
    bottom: 1%;
    right: 4%;
    font-size: 0.688rem;
    color: grey;
}

.data-cell {
    padding-left: 0.625rem;
    padding-right: 0.625rem;
    padding-bottom: 0.625rem;
    padding-top: 0.625rem;
    height: 5rem !important;
}

.description-input {
    width: 100%;
    border: 1px solid black;
    border-radius: 0.625rem;
    height: 3.75rem;
    margin: 0;
}

.add-btn-widget {
    border-radius: 0.313rem;
    width: 5rem;
    height: 3.125rem;
    background-color: #355E3B;
    color: white;
    border-style: none;
    border: none;
    margin: 0;
}

.add-edit-button {
    border-radius: 0.313rem;
    width: 5rem;
    height: 3.125rem;
    background-color: #0d669e;
    color: white;
    border-style: none;
    border: none;
    margin: 0;
}

.delete-btn {
    border-radius: 0.313rem;
    width: 5rem;
    height: 3.125rem;
    background-color: darkred;
    color: white;
    border-style: none;
    border: none;
    margin: 0;
    margin-left: 1.25rem;
}

.merch-input {
    width: 25rem;
}

.merchant-id-input {
    width: 100%;
    height: 3.75rem;
    margin-top: 0;
    border-radius: 0.625rem;
    padding-left: 5px;
    padding-right: 5px;
    text-align: left;
}

.btn-cell {
    display: flex;
    align-items: center;
    height: 3.75rem;
    padding-left: 0.625rem;
    justify-content: center;
    height: 5rem;
}

.data-row {
    padding-bottom: 0.625rem !important;
}

.mb-20 {
    padding-bottom: 2.5rem !important;
}

.pd-10 {
    padding-left: 0.625rem;
}

.widget-table {
    margin-top: 1.25rem;
    margin-left: 0;
}

.first-input-cotainer {
    display: flex;
    align-items: center;
    gap: 1.25rem;
}
