.l1-category {
  font-size: $fs-30px;
  font-weight: bold;
  color:$l1-color;
  padding-top: 0.5rem;
  visibility: visible;
}

.l2-category {
  font-size: $fs-25px;
  font-weight: 700;
  color:$l2-color;
  visibility: visible;

}


.l3-category {
  font-size: $fs-20px;
  font-weight: 600;
  color:$l3-color;
  visibility: visible;

}

.l4-category {
  font-size: $fs-18px;
  font-weight: 500;
  color:$black;
  visibility: visible;

}

.icons {
  color: $theam !important;
}

#page-content-wrapper > div > div:nth-child(3) > div > form > div > div > div{
  border: 1px solid !important;
  position: initial;
}

.user-logo {
  float: right !important;
}

#page-content-wrapper > div.Merchentlist > div > div > div {
  border: 1px solid !important;
  // position: initial;
}
#page-content-wrapper > div.qcpanelclass_info > div > div > div{
   border: 1px solid !important;
  // position: initial;
}