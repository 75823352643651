$navy-blue: #6F7DA4;
$light-blue: #EBF0FF;
$light-grey: #ACB2C6;
$light-green: #9EC83D;
$green:#A2DF14;
$opacity-blue: #6F7DA4;
$red: #F37B7B;
$opacity-Amber: #FCDE74;
$black: #000000;
$white: #ffffff;
$gray: #E5E5E5;
$light-red: #FF3838;
$sliver-sand: #c4c4c4;
$sliver-grey: #BCBCBC;
$crimson-red: #DC0D45;
$theam: #0375B0;
$theme-dark : #013A58;
$l1-color: #012e46;
$l2-color: #014669;
$l3-color: #025d8c;
$l4-color: #0375b0;