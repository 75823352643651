.qc_head{
    background-color: #CDECF4;
    color: #333333;
    border:0 !important;
}
.agent_input{
    width:60%;
    float:left;
}
.agent_button{
    width:33%;
}
.header {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}

.status_list {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    border: 1px solid #accdd5 !important;
    width: auto;
    height: auto;
    padding: 10px 10px 10px 10px;
}

.status_list h6 {
    padding: 3px 0px 0px 0px;
    margin: 0px;
    text-align: center;
    display: inline-block;
}

.instruction {
    box-sizing: border-box;
    width: 60% ;
    max-width: 60% ;
    height: auto;
    background: rgba(205, 236, 244, 0.5);
    border: 1px solid #50EEF9;
    margin: 0px 0px 40px 0px;
}

.instruction_1 {
    box-sizing: border-box;
    width: 34% !important;
    max-width: 34% !important;
    height: auto;
    background: rgba(205, 236, 244, 0.5);
    border: 1px solid #50EEF9;
    margin: 30px 0px 40px 0px;
}

.shopboard_input {
    display: flex;
    justify-content: space-between;
}

.reason {
    width: 50% !important;
    max-width: 50% !important;
    margin: 22px 5px 0px 5px;
}


.btn {
    background: #E98424;
    width: 100% !important;
    max-width: 100% !important;
    color: #ffffff;
    box-sizing: border-box;
    position: relative;
    border-radius: 10px;
    padding: 8px 70px 8px 70px;
}
.Search_button{
    display: inline-block;
    width:9% !important;
    background-color: #0375B0 !important;
    border-radius: 0px !important;
    padding: 10px 76px 7px 27px !important;
    margin-top: 1.8%;
    margin-left: 2%;
  }
.input_business_name {
    width: 25% !important;
    max-width: 25% !important;
    right: 40%;
    position: sticky;
    caret-color: transparent;
}

.title {
    text-decoration: underline #38A162 !important;
    text-underline-offset: 0.5em !important;

}

.label_check {
    display: inline-block !important;
}

.input_match_business_name {
    width: 25% !important;
    max-width: 25% !important;
    position: sticky;
    caret-color: transparent;
}
.approveDiv{
    display: inline-block;
}