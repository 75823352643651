.category-inputs-container {
    display: flex;
    justify-content: space-between
}

.master-catalog-header {
    text-align: center;
    font-size: 2rem;
    margin-bottom: 0.5rem;
}

.flex {
    display: flex;
}

.master-catalog-select {
    border: none;
    height: 2.5rem;
    margin-right: 1.25rem;
    border-radius: 0.313rem;
    background-color: #efefef;
}

.w-100 {
    width: 100%;
}

.top-container {
    margin-bottom: 1.25rem;
}

.view-all-btn {
    width: 5rem;
}

.master-catalog-btn {
    height: 2.5rem;
    width: 5rem;
    background-color: #0d669e;
    color: white;
    border-radius: 5px;
}

.master_cat-search-btn-disabled {
    height: 2.5rem;
    width: 5rem;
    background-color: grey;
    color: white;
    border-radius: 5px;
}

.add-upload-btn {
    margin-right: 1.25rem;
}

.td-value {
    word-break: normal;
}

.td-value-image {
    text-align: center;
}

.product-image {
    width: 3.125rem;
}

.add-inputs {
    width: 90%;
    text-align: left;
}

.edit-icon {
    width: 1.25rem;
}

.buttons-container {
    display: flex;
}

.cancel-btn {
    border-radius: 0.313rem;
    padding: 0.625rem;
    background-color: darkred;
    color: white;
    border-style: none;
    border: none;
    margin-left: 0 !important;
}

.save-btn {
    margin-right: 0.5rem !important;
}

.showmore-container {
    display: flex;
    justify-content: flex-end;
    padding-right: 3%;
    padding-top: 1%;
}

.showmore-text {
    color: blue;
    cursor: pointer;
}

.invisible-file-input {
    display: none;
}

.master-catalog-count {
    text-align: center;
    font-size: 1.125rem;
    font-weight: 600;
}

.search-csv-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.export-csv-btn {
    margin-right: 1.25rem;
}

.normal-cell {
    width: 7.5rem;
}

.large-cell {
    width: 10.625rem;
}

.nodata-text {
    text-align: center;
    margin-top: 1.5rem;
    font-size: 1.125rem;
    font-weight: 600;
}

.each-image-view {
    width: 6.25rem;
}

.fit-content {
    height: fit-content;
}

.images-edit-text {
    display: flex;
    justify-content: space-evenly
}