

.searchButton{
    border-radius: 5px;
    padding: 10px;
    background-color: #0d669e;
    color: white;
    border-style: none;
    border: none;
    margin-top: 0.5rem;
}

.searchButtonDisabled {
    border-radius: 5px;
    padding: 10px;
    background-color: grey;
    color: white;
    border-style: none;
    border: none;
    margin-top: 0.5rem;
}

.mainContainer{
    display: flex;
    width: 100%;
}



.left{
    /* background-color: brown; */
    width:40%;
    display: flex;
    padding: 1rem;
    /* justify-content: space-between; */
    
}

.right{
    /* background-color: blueviolet; */
    width: 60%;
    display: flex;
    flex-direction: column;
    gap: 3rem;
    padding: 1rem;
}

.textFieldStyle{
    width: 50%;
}

.textBoxStyle{
    width: 80%;
}

/* .dropDown{
    width: 25%;
    margin-left: 2%;
    margin-right: 4%;
} */

.dropDown {
    margin-left: 2%;
    margin-right: 4%;
}



/* Style the dropdown container */
.dropDown::after {
    content: "\25BE"; /* Unicode character for down arrow */
    position: absolute;
    top: 50%;
    right: 0.5em;
    transform: translateY(-50%);
    pointer-events: none;
}

/* Style the dropdown options */
.dropDown select option {
    background-color: #fff;
    color: #000;
}

/* Style the dropdown when it's open */
.dropDown select:focus {
    outline: none;
    box-shadow: 0 0 0 2px #007bff;
}

.changeRider{
    display: flex;
    align-items: center;
    
}

.box-border {
    border: 1px solid black;
    padding: 1.5rem;
}

.all-box-container {
    display: flex;
    justify-content: center;
    margin-bottom: 2rem;
}

.box {
    margin-right: 0.3rem;
    margin-left: 0.3rem;
    border: 1px solid black;
    height: 19rem;
    width: 12rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.time{
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    margin-right: 2%;
}

.insideTab{
    display: flex;
    flex-direction: column;
    width: 100%;
}


/* Tab menu styles */
.tabs {
    overflow: hidden;
    width: 100%;
    display: flex;
    justify-content: center;
}

.tabs button {
    background-color: inherit;
    float: left;
    border: none;
    outline: none;
    cursor: pointer;
    /* padding: 14px 16px; */
    transition: 0.3s;
    font-size: 17px;
}

.tabs button:hover {
    background-color: #ddd;
}

.tabs button.active {
    background-color: #ccc;
}

/* Tab content styles */
.tabcontents {
    display: none;
    padding: 6px 12px;
    border: 1px solid #ccc;
    border-top: none;
}

.tabcontents.active{
    display: block;
    padding: 6px 12px;
    border: 1px solid #ccc;
    border-top: none;
}

.tablink{
    padding: 0.5rem;
    margin: 0.5rem;
}


.editOrderTabData{
    display: flex;
    flex-direction: row;
}

.flexAlignMapData{
    display: flex;
    flex-direction: column;
}

.mapStyle {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 2%;
}

.tableHeaderAlign{
    text-align: center;
    margin-bottom: 30px;
}

.tableDataAlign{
    border: 1px solid #000000;
    padding-top: 9px;
    padding-bottom: 9px;
    border-width: 0.5px;
    word-break: break-all;
    padding: 9px;
    text-align: center;
}

.tableRowAlign{
    padding: 9px;
}

.mapStyle {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 2%;
}

.liveOrderth{
    width: 100%;
}

.delivery-pickup-image {
    width: 2.5rem;
}

.address-text {
    word-break: normal !important;
}

.address-cell {
    width: 9rem;
}