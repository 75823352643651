.catalogue-container {
    padding: 10px 20px;
}

.catalog-search-container {
    display: flex;
    gap: 10px;
    align-items: center;
    margin-left: 20px;
}

.catalog-search-btn {
    height: 2.5rem;
    width: 5rem;
    background-color: #0d669e;
    color: white;
    border-radius: 5px;
    margin: 0;
}

.unmatch-btn-container {
    display: flex;
    justify-content: center;
}

.matched-table-data {
    display: flex;
    gap: 50px;
    align-items: center;
    margin-left: 20px;
}

.unmatched-table-first-data {
    padding-left: 20px;
    padding-right: 20px;
}

.ml-20 {
    margin-left: 20px;
}

.unmatched-pd-image {
    width: 50px;
    height: 50px;
}

.pd-name-image-field {
    display: flex;
    align-items: center;
    gap: 10px;
}

.upload-catalog-btn {
    height: 2.5rem;
    padding: 5px 10px;
    background-color: #0d669e;
    color: white;
    border-radius: 5px;
    margin: 0;
    border: none;
}

.no-btn-style {
        height: 2.5rem;
        padding: 5px 10px;
        background-color: darkred;
        color: white;
        border-radius: 5px;
        margin: 0;
        border: none;
        width: 80px;
}

.upload-catalog-btn::placeholder {
    color: white;
}

.upload-catalog-btn :active {
    background-color: #0d669e;
}

.price-cell-catalog {
    width: 120px;
}

.name-prices-container {
    display: flex;
    gap: 20px;
    justify-content: flex-start;
}

.export-excel-select-container {
    display: flex;
    gap: 30px;
    align-items: center;
}

.yes-no-btn-container {
    display: flex;
    justify-content: center;
    margin-top: 1rem;
    gap: 10px;
}

.large-font {
    font-size: 18px !important;
}

.refresh-text {
    color: #0d669e;
    cursor: pointer;
    margin-left: 5px;
}

.uncut-text {
    white-space: nowrap !important;
}

.data-label {
    font-size: 16px;
}

.normal-text {
    word-break: normal;
}