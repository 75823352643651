.login-div {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 200px;
    gap: 20px;
}

.ondc-cell {
    border: 1px solid #ececec;
    background-color: #f8fcfd;
    color: #6c6f6e;
    padding: 0 10px;
    font-weight: 200;
}

.ondc-page-header {
    text-align: center;
    font-weight: 600;
    margin: 0;
}

.ondc-data-cell {
    padding-top: 9px;
    padding-bottom: 9px;
    border-width: 0.5px;
    word-break: break-word;
    padding: 9px;
    text-align: center;
    border: 1px solid #ececec;
}

.clip-loader {
    height: 30px !important;
    width: 30px !important;
    vertical-align: middle;
}

.ondc-header-div {
    display: flex;
    margin-left: 47%;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
    margin-top: 10px;
}

.ondc-date-filter {
    margin-right: 0;
    margin-left: 0;
    width: 120px;
    margin-top: 0;
}

.filter-container {
    display: flex;
    align-items: center;
    gap: 5px;
}

.react-datepicker-wrapper {
    width: auto !important;
}

.ondc-table-header {
    position: sticky;
    top: 0;
}

.ondc-input {
    width: 300px;
    height: 50px;
    text-align: start;
    padding: 0 10px;
    border-radius: 5px;
    border: 1px solid black;
}

.ondc-login-text {
    margin-top: 10px;
    font-size: 20px;
}

.ondc-filter-btn {
    height: 1.8rem;
    width: 5rem;
    background-color: #0d669e;
    color: white;
    border-radius: 5px;
}

.filters-export-container {
    display: flex;
    align-items: center;
    gap: 30px;
}