@import './stylesheets/app';
.App {
  /* text-align: center; */
  
}

* {
  font-family: 'Proxima-regular';
}

h3, h4, h5, h6 {
  font-family: 'Proxima-bold' !important;
}

.mobileNo{
  display: block;
  margin: 0px auto;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

table {
  width: 98%;
  table-layout: fixed;
  margin: 0px auto;
}
table tr th {
  /* text-align: left; */
  background: gray;
  color: white;
  height: 2rem;
}
.css-i4bv87-MuiSvgIcon-root{
  font-size: inherit !important;
  color: grey;
  cursor: pointer;
}
/* .pd-left-2rem{
  padding-left: 2rem;
}
.display-flex{
  display: flex !important;
}*/
.bg-style{
  background: #f3f1f1;
  padding: 1.5em;
  border-radius: 1em;
} 
.center{
  display: flex; 
  justify-content: center;
  align-items: center;
}
.font-12rem{
  font-size:1.2rem;
}
.mg-left-1{
  margin-left: 1rem;
}
.cursor-pointer{
  cursor: pointer;
}
/* .mg-left-50{
  margin-left:50rem;
} */
.modal-header-container {
  border-bottom: 2px solid #f3f1f1 !important;
}

.edit-img-file {
  position: absolute;
  // right:0;
  border-radius: 50%;
  width: $fs-24px;
  height: $fs-24px;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0.312rem;
  cursor: pointer;
}

.payout-tblcell{
  background-color: white;
  color: black;
}

.align-center {
  text-align: center !important;
}

.payout-pd-name {
  word-break: normal !important;
}

.price-cell {
  display: flex !important;
  justify-content: center !important;
}

.payout-tblcell-summary {
  background-color: #ececec;
  color: #0a70a5 !important;
  height: 73px;
}

.payout-calender{
  display: block;
  width: 100%;
  margin: 0;
  text-align: center;
  margin-top: 0;
}

.deduction_field:hover {
  cursor: pointer;
}

.refund_submit_btn {
  background-color: #1976d2;
  color: white;
}

.payout_container {
  width: 100%;
  margin-left: 0;
}

.vip_history_submit_btn {
  border-radius: 5px;
  padding: 10px;
  background-color: #0d669e;
  color: white;
  border-style: none;
  border: none;
}

.vip_history_submit_btn-disabled {
  border-radius: 5px;
  padding: 10px;
  background-color: grey;
  color: white;
  border-style: none;
  border: none;
}

.vip_history_table_head {
  font-weight:800 !important;
}

.summary_pd_num {
  font-weight: 500;
}

.summary_cutout_price {
  font-weight: 500;
  color: #7d7d7d !important;
  font-size: 12px !important;
  text-decoration: line-through;
  margin-right: 10px;
}

.shop_status_submit_btn {
  margin: 0;
  border-radius: 0.3rem;
  background-color: #0375b0;
  color: white;
  width: 4.5rem;
  height: 1.4rem;
}

.payout_balance_change_container {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.payout_balance_edit_input {
  width: 5rem;
  height: 1.8rem;
  text-align: start;
  margin-bottom: 0.5rem;
  border-style: none;
  border: 1px solid lightgrey;
  border-radius: 0.25rem;
  margin-left: 0;
}
.edit_icon {
  height: 0.938rem;
  align-self: flex-start;
  margin: 0;
  margin-left: 0.18rem;
  cursor: pointer;
}

.add_cancel_container {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.cancel_text {
  margin-left: 1rem;
}

.payout_balance {
  font-weight: 600;
  margin-left: 0.5rem;
  margin-right: 0.25rem;
  font-size: 1.2rem;
}

.page_container {
  margin-top: 180px;
  padding-left: 2.5rem;
  padding-bottom: 2.5rem;
}

.page-container {
  padding-bottom: 2.5rem;
}

.vip_history_input_container {
  display: flex;
  width: 31.25rem;
  align-items: center;
}

.vip_history_input {
  width: 21.875rem;
}

.refund_input_column {
  display: flex;
  flex-direction: column;
}

.be-content-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.cashback_btn {
  border-radius: 0.3rem;
  background-color: #0375b0;
  color: white;
  padding: 0.3rem;
  word-break: normal !important;
}

.wallet-btn {
  padding: 0.3rem 0.9rem
}

.cashback_dialog {
  padding: 3.75rem;
  padding-top: 3rem;
}

.single-input {
  margin-right: 3rem !important;
}

.buttons-container {
  display: flex;
  justify-content: space-evenly;
}

.cashback-submit-btn {
  margin-top: 2rem;
}

.cashback-submit-btn-disabled {
  border-radius: 0.3rem;
  padding: 10px;
  background-color: grey;
  color: white;
  border-style: none;
  border: none;
  margin-top: 2rem;
}

.close_icon {
  position: absolute;
  right: 0;
  top: -2%;
  width: 1.87rem;
  margin-right: 0.125rem;
}

.view_icon {
  width: 1.875rem;
  height: 1.875rem;
}

.edit-icon {
  width: 1.25rem;
  height: 1.25rem;
}

.csv-format-btn {
  background: #E98424;
  color: #ffffff;
  box-sizing: border-box;
  position: relative;
  border-radius: 5px;
  padding: 8px 10px 8px 10px;
  height: 2.5rem;
  margin-right: 1.25rem;
}

.ondc-csv-btn {
  height: 1.8rem;
  background-color: #E98424;
  color: white;
  border-radius: 5px;
  margin: 0;
  padding: 0 5px;
}

.yn-btn-container {
  display: flex;
  justify-content: center;
  margin-top: 1rem;
}

.no-btn {
  border-radius: 0.313rem;
  width: 5rem;
  height: 2.3rem;
  background-color: darkred;
  color: white;
  border-style: none;
  border: none;
  margin-left: 1.25rem;
}

.yes-btn {
  border-radius: 0.313rem;
  width: 5rem;
  height: 2.3rem;
  background-color: #0375b0;
  color: white;
  border-style: none;
  border: none;
  margin-right: 1.25rem;
}

.confirm-dialog-text {
  font-size: 1.3rem;
}

.order-summ-modal {
  min-width: 300px;
}

.order-summary-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.grey-text {
  font-size: 12px;
  color: grey;
}

.order-summary-container-next {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
  border-top: 1px solid grey;
  padding-top: 10px;
  width: 100%;
}

.img-name-container {
  display: flex;
  align-items: center;
  margin-right: 20px;
  width: 130px;
}

.pd-image {
  width: 30px;
  height: 30px;
  margin-right: 5px;
  border-radius: 5px;
}

.price-container {
  display: flex;
  align-items: center;
  margin-right: 20px;
  width: 80px;
  justify-content: flex-end;
}

.cutout-price {
  text-decoration: line-through;
  margin-right: 5px;
  font-size: 12px;
  color: grey;
}

.qty-container {
  display: flex;
}

.fixed-count {
  margin-right: 20px;
  text-align: start;
}

.plus-minus-container {
  display: flex;
  justify-content: space-evenly;
  width: 85px;
  height: 28px;
  align-items: center;
  border: 1px solid rgba(18,93,141);
  padding-top: 2px;
  border-radius: 5px;
  background-color: white
}

.plus_minus_text_grid {
  font-size: 21px !important;
  color: #125d8d;
  margin: 0 !important;
  font-weight: 500 !important;
  flex: 1;
  text-align: center;
  cursor: pointer;
}

.product_count {
  color: #125d8d;
  flex: 1;
  text-align: center;
}

.beat-loader-container {
  min-height: 150px;
  min-width: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.initial-search-container {
  display: flex;
  justify-content: left;
  margin-top: 1rem;
  max-height: 2.5rem;
  gap: 1rem;
}

.ONDC-select {
  margin-bottom: 0.625rem;
}

.sidebar {
  height: 5rem;
}

.menu-background:hover {
  background-color: lightgrey;
}

.menu-background {
  margin-left: 1.875rem;
  border-radius: 0.625rem;
}

.top-bar-menu-icon {
  width: 2.5rem;
  height: 2.5rem;
  cursor: pointer;
}

.whole-container {
  padding: 0 1.875rem;
}

.be-mapping-header {
  margin-left: 1.5rem;
}

.drawer-scroll::-webkit-scrollbar {
  width: 5px !important; /* width of the scrollbar */
}

.clip-loader {
  height: 30px !important;
  width: 30px !important;
  vertical-align: middle;
}

.top-bar {
  display: flex;
  justify-content: space-between;
  height: 4.375rem;
  align-items: center;
}

.page-number-data {
  margin-top: -3px;
  float: right;
}

.cb-employee-submit-btn {
  width: 180px;
  border-radius: 10px;
  background-color: #E98424;
  border: none;
  color: white;
  height: 40px;
  margin: 0;
}

.cb-employee-btn-container {
  display: flex;
  justify-content: flex-end;
}

.export-excel-btn {
  margin-right: 78px;
}

.add-employee-inputs-container {
  margin: 4.063rem 5rem 0.625rem 5rem;
}

.textfields-row-container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 2%;
  margin: 4% 0;
  height: auto;
  width: 100%;
}

.pagination-div-business-dashboard {
  width: 100%;
  background-color: #CDECF4;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 40px;
}

.new-ticket-color {
  background-color: #A5EBEF;
}

.pending-ticket-color {
  background-color: #FFBD7E;
}

.closed-ticket-color {
  background-color: #62FDA0;
}

.rejected-ticket-color {
  background-color: #FD626B;
}

@media only screen and (min-width: 1400px) {
  .payout_container {
    margin-left: 10%;
    
  }
}
