

.wrapper{
    
  border: 2px solid rgb(0, 0, 0);
  margin-top: 10px;
  margin-left: 30px;
  background: #cdecf4;
  text-align: center;
  border-radius: 6px;
}

.wrapperSummaryPanel {
  padding-top: 1px;
  padding-bottom: 1px;
  display: inline-block;
  width: 25%;
  padding-left: 2px;
  padding-right: 2px;
  font-weight: bold;
  text-align: center;
  border: 2px solid rgb(0, 0, 0);
  margin-top: 10px;
  margin-left: 30px;
  background: #cdecf4;
  border-radius: 6px;
}


th {
  background-color: #cdecf4;
  /* border: 0!important; */
  color: #333;
    
  }
  
td{
  background-color: #fff;
  word-break: break-all;
}
.orderList_table{
  /* height: 70vh; */
    margin-top: 6%!important;
    margin: 0 auto;
    overflow-y: auto;
    width: 99%;
}

.orderList_table_empty {
    margin-top: 6%!important;
    /* margin-bottom: 6% !important; */
    margin: 0 auto;
    overflow-y: auto;
    width: 99%;
}

.no-data-div {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60px;
}

.grid-col
{
  display: grid;
   grid-template-columns: 1fr 1fr 1fr ifr;
}
td.order_td {
  width: 49%;
  font-weight: bold;
}
.orderIdList{
  border:1px solid #000;
 
  width: 100%;
}




/* Tab menu styles */
.tab {
  overflow: hidden;
  /* background-color: #f1f1f1; */
  width: 100%;
  display: flex;
  justify-content: center;
}

.tab button {
  background-color: inherit;
  float: left;
  border: none;
  outline: none;
  cursor: pointer;
  /* padding: 14px 16px; */
  transition: 0.3s;
  font-size: 17px;
}

.tab button:hover {
  background-color: #ddd;
}

.tab button.active {
  background-color: #ccc;
}

/* Tab content styles */
.tabcontent {
  display: none;
  padding: 6px 12px;
  border: 1px solid #ccc;
  border-top: none;
}

.tabcontent.active{
  display: block;
  padding: 6px 12px;
  border: 1px solid #ccc;
  border-top: none;
  
}

.tablinks{
  padding: 0.5rem;
  margin: 0.5rem;

}

.cardDesign{
  padding: 2rem;
  border-top: 0.1rem solid black;
  border-left: 0.1rem solid black;
  border-right: 0.1rem solid black;
}


.labelStyle{
  
  align-self: center;
  font-weight: 700;
  font-size: 1.2rem;
  padding-top: 1px;
  padding-bottom: 1px;
  display: inline-block;
  width: 25%;
  padding-left: 2px;
  padding-right: 2px;
  
}

.cardRowStyle{
  display: flex;
  flex-direction: row;
  margin: 0 auto;
}

.lowerCardContent{
  padding: 2rem;
  border: 0.1rem solid black;
}

.tableTextStyle{
  text-align: center;
}
