@import './colors';
@import './mixins';

.custom-add-btn {
    font-family: $roboto-bold;
    font-size: $fs-25px;
    border-radius: $fs-4px !important;
    background-color: $theam !important;
    color: $white !important;
    border-color: $theam !important;
    height: $fs-54px !important;
    @include setFont(700, normal, normal, normal, center);
  @include setPadding(
    $fs-8px !important,
    $fs-10px !important,
    $fs-8px !important,
    $fs-10px !important
  );
//   @include setBtnColor(
//     $theme-dark !important,
//     $theme-dark !important,
//     $white !important
//   );
  &:hover {
    @include setBtnColor(
      $theme-dark !important,
      //background-color
      $white !important,
      //border-color
      $white !important//color
    );
    border-color: $theme-dark !important;
  }
  &:focus {
    box-shadow: none !important;
    @include setBtnColor(
      $theme-dark !important,
      $theme-dark !important,
      $white !important
    );
    border-color: $theme-dark !important;
  }
  &:disabled {
    @include setBtnColor(
      $theam !important,
      $theam !important,
      $black !important
    );
    cursor: not-allowed;
    border-color: $theam !important;
  }
}

.btn_submit {
  height: $fs-40px !important;
  border-radius: 10px !important;
  border-style: none;
  font-weight: bold;
  font-family: $roboto-bold !important;
  font-size: $fs-20px !important;
  @include setBtnColor($light-green !important,$light-green !important,$white !important);
  &:hover{
    @include setBtnColor($light-green !important,$light-green !important,$white !important);
    font-weight: bold;
    font-family: $roboto-bold !important;
  };
  &:disabled {
    @include setBtnColor(
      $sliver-sand !important,
      $sliver-sand   !important,
      $white !important
    );
    cursor: not-allowed;
    border-color: $theam !important;
  }
}
.status-btn_submit {
  width: 90%;
  height: $fs-40px !important;
  border-radius: 10px !important;
  border-style: none;
  font-weight: bold;
  font-family: $roboto-bold !important;
  font-size: $fs-20px !important;
  @include setBtnColor($light-green !important,$light-green !important,$white !important);
  &:hover{
    @include setBtnColor($light-green !important,$light-green !important,$white !important);
    font-weight: bold;
    font-family: $roboto-bold !important;
  };
  &:disabled {
    @include setBtnColor(
      $sliver-sand !important,
      $sliver-sand   !important,
      $white !important
    );
    cursor: not-allowed;
    border-color: $theam !important;
  }
}
.btn_cancel {
  height:$fs-40px !important;
  border: 1px solid $navy-blue !important;
  border-radius: 10px !important;
  font-weight: normal !important;
  font-size: $fs-20px !important;
  // padding: 0 !important;
  @include setBtnColor($white !important,$navy-blue !important,$black !important)
}

//servicefilter button


.buttons{
  padding-right:$fs-5px ;
  display: inline-flex !important;
  
  
}

 .buttonStyle{
    border-color:$navy-blue !important;
   background-color:$white !important;
  background-color:$white !important;
  color:$black !important;   
  height:$fs-40px !important ;
  display:inline-block;
 
  
  
    
  // width:200px !important;

}
  .buttonStyle img{
   width:$fs-15px !important;
   padding-left:$fs-3px !important;
   margin-left:$fs-13px !important;
   //position:relative; 
  //  display:block;

 }
.Form-cancel-btn{
  color:$white !important ;
  height:$fs-40px !important;
  font-size: $fs-18px !important;
  background-color:$light-green !important;
  font-weight: bold;
  border-radius:10px !important;
  //@include setBtnColor($light-green !important,$light-green !important,$light-green !important)
 }
 .Form-submit-btn{
  font-size: $fs-18px !important;
  height:$fs-40px;
  background-color:$light-green !important;
  font-weight: bold;
  border-radius:10px !important;
  // @include setBtnColor($light-green !important,$light-green !important,$light-green !important)
 }
 .btn-update{
  background-color:$sliver-sand !important;
  border-radius:10px !important;
  height:40px !important;
  font-weight: bold;
  font-size:$fs-20px !important;
  color:white !important;
  font-size: $fs-20px !important;
  // background-color:$light-green !important;
  @include setBtnColor($light-green !important,$light-green !important,$white !important);
  &:hover{
    @include setBtnColor($light-green !important,$light-green !important,$white !important);
    font-weight: bold;
    font-family: $roboto-bold !important;
  };
  &:disabled {
    @include setBtnColor(
      $sliver-sand !important,
      $sliver-sand   !important,
      $white !important
    );
    cursor: not-allowed;
    border-color: $theam !important;
  }

  // width:100% !important;
}
.btn-update-cancel{
  background-color:$white !important;
  border-radius:10px !important;
  height:40px !important;
  font-weight: bold;
  font-size:$fs-20px !important;
  color:white !important;
  font-size: $fs-20px !important;
  border-color: $navy-blue !important; 
  color:black !important;
}
.add-btn{
  // background-color:$light-green !important;
  color:black !important;
  border-radius: $fs-10px !important;
   height:40px !important;
   text-align: center;
   width:80% !important;
   @include setBtnColor($light-green !important,$light-green !important,$black !important);
  &:hover{
    @include setBtnColor($light-green !important,$light-green !important,$black !important);
    font-weight: bold;
    font-family: $roboto-bold !important;
  };
  &:disabled {
    @include setBtnColor(
      $sliver-sand !important,
      $sliver-sand   !important,
      $white !important
    );
    cursor: not-allowed;
    border-color: $theam !important;
  }
  
   
}
.add-doc{
  // background-color:$light-green !important;
  color:black !important;
  border-radius: $fs-10px !important;
   height:40px !important;
   text-align: center;
   width:80% !important;
   @include setBtnColor($green !important,$green !important,$black !important);
  &:hover{
    @include setBtnColor($green !important,$green !important,$black !important);
    font-weight: bold;
    font-family: $roboto-bold !important;
  };
  &:disabled {
    @include setBtnColor(
      // $sliver-sand !important,
      // $sliver-sand   !important,
      // $white !important
      $green !important,$green !important,$black !important
    );
    cursor: not-allowed;
    border-color: $black !important;
  }
  
   
}

//admin Button

.adminbtn_cancel{

  @include setBtnColor(
   
    $light-green !important,$light-green !important,$light-green !important
  );
  color: $white !important;
  font-weight: bold !important;
  size:24px !important;
  font-size: $fs-20px !important;
  border-radius: 10px !important;

}

