.order-details-container {
    display: flex;
    justify-content: space-between;
    padding: 0.625rem 1.25rem;
    flex-wrap: wrap;
}

.row-flex {
    display: flex;
    gap: 0.3125rem;
    margin-left: 0.625rem;
}

.shadow-container-header {
    font-weight: 600;
    margin-top: 0.9375rem;
    margin-left: 0.625rem;
}

.individual-table {
    padding: 0 1.25rem;
    margin-top: 0;
    margin-bottom: 0;
}

.order-summ-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.order-summ-container-next {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 0.625rem;
    border-top: 0.0625rem solid #ececec;
    padding-top: 0.625rem;
    width: 100%;
}

.order-summ-text {
    cursor: pointer;
    color: #1e9dff;
    font-weight: 600;
}

.newss {
    padding-bottom: 0.625rem;
}

.table-last-row {
    padding-bottom: 0.625rem;
    border-radius: 20px;
}

.order-details-header {
    margin-left: 1.875rem;
    margin-top: 0.625rem;
    margin-bottom: 0;
}

.first-row  {
    padding-top: 0.625rem;
}

.item-summary-container {
    background-color: white;
    padding: 10px;
}

.bill-header-container {
    display: flex;
    justify-content: space-between;
}

.bill-container {
    margin-top: 20px;
    background-color: white;
    padding: 10px;
}

.items-saving-container {
    display: flex;
    gap: 5px;
}

.bill-data-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.bill-data-grey-text {
    color: grey;
}

.bill-data-green-text {
    color: #2eb576;
    margin-bottom: 0 !important;
}

.amount-paid-text {
    font-size: 18px;
}

.data-header {
    color: grey;
    word-break: normal;
}

.data-text {
    font-size: 15px;
    font-weight: 600;
}

.grey-divider {
    border-bottom: 1px solid #e4e4e4;
    margin: 10px 0;
}

.order-summary-dialog {
    padding: 1rem 0;
    width: 450px;
    background-color: #eeeeee;
}

.item-price-container {
    display: flex;
    align-items: center;
    width: 80px;
    justify-content: flex-end;
}

.cross-icon {
    width: 20px;
    height: 20px;
    position: absolute;
    right: 1%;
    top: 1%;
}

.green-text-savings {
    color: #2eb576;
}

.order-img {
    width: 50px;
    height: 50px;
    border-radius: 5px;
}

.items-align {
    align-items: center;
}

.order-summ-table {
    margin-top: 1.25rem;
    margin-left: 0;
    width: 100% !important;
    padding: 0 1.25rem;
}

.left-padding {
    padding-left: 10px;
}

.order-details-head {
    margin-left: 10px;
    margin-bottom: 0;
}

.order-summ-count-changed {
    background-color: #f2f2f2;
    display: inline-block;
    padding: 0.625rem 0.938rem;
    border-radius: 0.313rem;
    color: #ff8d28;
    font-weight: 600;
}
